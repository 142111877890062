import React from "react"
import { Link } from "gatsby"
import styled, { keyframes } from "styled-components"
import Layout from "../components/layout/index.js"
import SEO from "../components/seo"

import { family, size } from "../elements/font"
import colors from "../elements/color"

const blink = keyframes`
  78% {
  color: inherit;
  text-shadow: inherit;
  }
  79%{
    color: #fff;
  }
  80% {
    
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: inherit;
  }
  82% {
    color: #fff;
    text-shadow: none;
  }
  83% {
    color: inherit;
    text-shadow: inherit;
  }
  92% {
    color: #fff;
    text-shadow: none;
  }
  92.5% {
    color: inherit;
    text-shadow: inherit;
  }
`

const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
`

const Title = styled.h1`
  font-family: ${family.Alata};
  font-size: 13rem;
  letter-spacing: 1rem;
  font-weight: 100;
  margin-top: 10vh;
  margin-bottom: 0;
  animation: ${blink} linear infinite 2s;
  @media only screen and (max-width: 900px) {
    font-size: 5rem;
  }
`

const Error = styled.p`
  font-family: ${family.Alata};
  font-size: ${size.L};
  margin-top: 1rem;
  @media only screen and (max-width: 900px) {
    font-size: 1.4rem;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 6vw;
  margin-top: 10vh;
  @media only screen and (max-width: 900px) {
    font-size: 5rem;
    flex-direction: column;
    margin-top: 5vh;
  }
`

const Text = styled.p`
  font-size: ${size.SSM};
  @media only screen and (max-width: 900px) {
    text-align: center;
    font-size: ${size.S};
  }
`

const Button = styled(Link)`
  font-family: ${family.Archivo};
  border: 4px solid ${colors.darkBlue};
  padding: 40px 200px;
  color: ${colors.darkBlue};
  transition: 0.3s ease;
  & > span {
    position: relative;
    font-size: ${size.XL};
    letter-spacing: 0.05em;
  }
  & > span::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -5px;
    height: 4px;
    background: ${colors.darkBlue};
    transition: 0.3s ease;
  }
  &:hover {
    color: white;
    background: ${colors.darkBlue};
    & > span::after {
      background: white;
    }
  }
  @media only screen and (max-width: 900px) {
    padding: 40px 100px;
    margin-top: 5vh;
    & > span {
      vertical-align: super;
    }
  }
`

const NotFoundPage = ({ path }) => {
  return (
    <Layout
      noMenu={true}
      style={{ height: "100vh" }}
      hasScrolled={true}
      headerString={[""]}
    >
      <SEO title="404: Not found" location={path} />
      <Main>
        <Title>404</Title>
        <Error>error</Error>
        <Row>
          <Text>Mais tout va bien, il suffit de cliquer ici 👉</Text>
          <Button className="click" to="/">
            <span>HOME</span>
          </Button>
        </Row>
      </Main>
    </Layout>
  )
}

export default NotFoundPage
